import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/Layout";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Education`}</h1>
    <h2>{`Degree`}</h2>
    <p>{`Bachelor of Engineering (Honours) (Software Engineering `}{`[Real-Time Systems]`}{`),     Diploma in Professional Engineering Practice`}</p>
    <p>{`Undertaken full time over the past five years, this `}<a parentName="p" {...{
        "href": "https://www.uts.edu.au/"
      }}>{`UTS`}</a>{` course has equipped me with a set of technical and transferable skills in the engineering discipline. As required by the concurrent Diploma in Professional Engineering Practice, 12 months of industry experience completed over two 6-month internships provided an opportunity to apply this knowledge in practice alongside senior engineers.`}</p>
    <h3>{`Achievements`}</h3>
    <ul>
      <li parentName="ul">{`Distinction Average GPA (6.15)`}</li>
      <li parentName="ul">{`UTS-IEEE Capstone Award 2020`}</li>
      <li parentName="ul">{`Faculty of Engineering and Information Technology Dean’s List 2020`}</li>
      <li parentName="ul">{`Faculty of Engineering and Information Technology Dean’s List 2018`}</li>
    </ul>
    <h3>{`Notable Courses`}</h3>
    <h4>{`Engineering Capstone`}</h4>
    <blockquote>
      <p parentName="blockquote">{`The Capstone project is a significant piece of engineering work conducted in the final session(s) of the Bachelor of Engineering under the supervision of a member of academic staff.`}</p>
    </blockquote>
    <p>{`Over the 12-month Engineering Capstone course, I was introduced to the world of brain-computer interface (BCI)
technology by `}<a parentName="p" {...{
        "href": "https://www.uts.edu.au/staff/yukai.wang"
      }}>{`Dr YK Wang`}</a>{`. By leveraging several emerging technologies
including the Microsoft Hololens, g.tec Unicorn EEG and Turtlebot I was able to demonstrate the feasibility of
applying a BCI interface in an uncontrolled environment. Ultimately the project resulted in the first steps towards
applying an augmented-reality based BCI in a practical application.`}</p>
    <p>{`As a result of this work, I achieved a final grade of 99% and was awarded the `}<strong parentName="p">{`UTS-IEEE Capstone Award`}</strong>{` for innovation
and likelihood for commercialisation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d56797f673c08e05043283343eaa933e/fbd2c/bci-demo.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAH/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABpz7KB2IElMHouf/EABsQAQEBAAIDAAAAAAAAAAAAAAIBAxETAAQS/9oACAEBAAEFAvY0sY2cXaL583iZykDMk29uZiaB5//EABYRAQEBAAAAAAAAAAAAAAAAABARQf/aAAgBAwEBPwGGH//EABgRAAIDAAAAAAAAAAAAAAAAAAABEBEx/9oACAECAQE/ASh7H//EABwQAAMAAgMBAAAAAAAAAAAAAAABEQIxEiFBUf/aAAgBAQAGPwJ44dQXJto2W36N+kMkOmj/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhQZExUbH/2gAIAQEAAT8hHJspZ7KAQ8B9RUhBXH2MSy3UKrXmaNkoHdTj8//aAAwDAQACAAMAAAAQ08f+/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQEf/aAAgBAwEBPxATdhz/AP/EABcRAQADAAAAAAAAAAAAAAAAAAEAEBH/2gAIAQIBAT8QULOT/8QAHRABAAMAAgMBAAAAAAAAAAAAAQARITFBUXGBof/aAAgBAQABPxApPQdX14jlwtDrzCrT1jYepMcrHPol0JdC7rCKq8qc2bsrVHqBtMCC5LR5N/Z//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Demonstration of brain cap & Hololens",
            "title": "Demonstration of brain cap & Hololens",
            "src": "/static/d56797f673c08e05043283343eaa933e/b4294/bci-demo.jpg",
            "srcSet": ["/static/d56797f673c08e05043283343eaa933e/75985/bci-demo.jpg 150w", "/static/d56797f673c08e05043283343eaa933e/f93b5/bci-demo.jpg 300w", "/static/d56797f673c08e05043283343eaa933e/b4294/bci-demo.jpg 600w", "/static/d56797f673c08e05043283343eaa933e/8e1fc/bci-demo.jpg 900w", "/static/d56797f673c08e05043283343eaa933e/fbd2c/bci-demo.jpg 1180w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4>{`Software Engineering Studio 3A / 3B`}</h4>
    <blockquote>
      <p parentName="blockquote">{`In this subject students design and begin developing a large, complex software system and implement several professional practices required to support such critical software systems. They learn to explore the problem efficiently to develop solution designs that support rapid convergence to the best alternative.`}</p>
    </blockquote>
    <p>{`While this course stream stretched across 3 years of study, the final courses (3A & 3B) provided free rein to develop a production-level application amongst a team of peers. Throughout these courses, I led a team of approximately 10 developers to scope, design and implement a full-stack web application that provides hobbyist stock traders with a platform to collaborate and improve their trading abilities.`}</p>
    <p>{`Following this study, the application is extending to ultimately provide the foundation for an upcoming startup. As a result of this work, our team achieved high-distinction grades for both courses.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px",
          "borderRadius": "20px",
          "overflow": "hidden"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/034c083ca21471257a47b428cdb69f5e/07a9c/ses-project.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUUlEQVQ4y21TPW8TQRC9lr9EyR/gB9BQUyHR0FBQIdFBh4SQIiGaUEDFVywLgkSRgEhARFhgE0UxcXy58/nudvf2w495G68JIpZGt55783bem7lssVhg/3eBqtEIwaMoCkynU9R1jbZtwfcLnP6895jP55jNZquoqirm2qZBI5GFEJCXDbSxcM5FIr6w1sq5Qa/Xx6uXL7C+/hRb259WGGMMWtXGs9IaneS1PDPTdbCdid0RzNs6+d9JPj8pcP3qJTy4dRlXLl7A7ZvXYrdlWURC4lnvhUiY4URBRlaldJREACUQTGKtFB6vPUJv4zWerD3E234PnXSeZPNSWsKny3M4eWb0SUnhWUJeUi4LgrTkQ3IR0VdtdMTQFvrKIIT1GckI4o+ABGSQUMk7dtwZF8PbAO9CfM98kh7lyzkSpg4J4pleeN4ocoLka90gbw5Q6kMUSqIdwy5J0oDYyLmEXqYeBOibGl7AQSZeTY9Rt2KBUzASPLOYUllLMqqkov8ke+lKyS7anz/gf43gR0M0w6GArWwCd1G6FsN4MZWkfeWQVh1y7xJh8sWKZCtL4iVvnI9dnHpll+EinrXkSMM8Zygm7pp3shJiPmW2eh7zJKFM4tIgkndpmP90aKzBt8M3ONl9h+3x85j7Pt3E3mQTwUL2r4pY7iHruIPxs5OGyrL822EtA4iEvkN/5x72797A+4/3Mfm8gWeDOxgcf5ClXaCaz6JnlJe+c17AM/OroaQOO/FmnO+hHnzB5GAHs69bGFW7KOujuHtJ5lnpJEl7y/gDpB8uRPRe+ZEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "User interface mock of web application dashboard",
            "title": "User interface mock of web application dashboard",
            "src": "/static/034c083ca21471257a47b428cdb69f5e/0a47e/ses-project.png",
            "srcSet": ["/static/034c083ca21471257a47b428cdb69f5e/8a4e8/ses-project.png 150w", "/static/034c083ca21471257a47b428cdb69f5e/5a46d/ses-project.png 300w", "/static/034c083ca21471257a47b428cdb69f5e/0a47e/ses-project.png 600w", "/static/034c083ca21471257a47b428cdb69f5e/1cfc2/ses-project.png 900w", "/static/034c083ca21471257a47b428cdb69f5e/c1b63/ses-project.png 1200w", "/static/034c083ca21471257a47b428cdb69f5e/07a9c/ses-project.png 1440w"],
            "sizes": "(max-width: 600px) 100vw, 600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      